
p {
  line-height:1.5;
  font-size:14;
  text-align:"center";
  font-family:"minion-pro, serif";
  padding-left:75;
  padding-right:75;

}

h1 {
  font-family:"minion-pro, serif"
}
h2 {
  font-family:"minion-pro, serif"
}
h3 {
  font-family:"minion-pro, serif"
}
li {
  font-size: 14;
  font-family: "minion-pro, serif";
  line-height:1.5;

}
.Background{
  background-image: url("../images/FadedBackground.jpeg");
  flex:1;
  align-items:stretch;
  background-size: cover;
  height: 90vh;
  background-position: center center;
}

.Commitment{
  background-image: url("../images/FadedCommitment.jpeg");
  flex:1;
  align-items:stretch;
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;



}
.GetQuote{
  background-image: url("../images/FadedGetQuote.jpeg");
  flex:1;
  align-items:stretch;
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
 

}
.Home{
  background-color:"#fff";
  align-items:stretch;
  border-color:black;

}
.Services{
  background-image: url("../images/FadedServices.jpeg");
  flex:1;
  align-items:stretch;
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;


}